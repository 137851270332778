import { Pipe, PipeTransform } from '@angular/core'
import { OrderClassEnum } from '../enums/order-class.enum'

@Pipe({
	name: 'extendedOrderClass'
})
export class ExtendedOrderClassPipe implements PipeTransform {
	transform(orderClass: OrderClassEnum | undefined): string {
		const startTitle = 'Группа ордеров'
		if (!orderClass) return startTitle
		return `${startTitle} (${orderClass?.toUpperCase()})`
	}
}
