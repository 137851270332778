import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Output,
	ViewEncapsulation
} from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectComponent),
			multi: true
		}
	]
})
export class SelectComponent {

	@Input() label: string
	@Input() items: any[] = []
	@Input() bindLabel: string
	@Input() bindValue: string
	@Input() clearable: boolean = false
	@Input() classes: string = ''
	@Input() disabledSelect: boolean = false

	@Input() ngModel: any
	@Output() ngModelChange = new EventEmitter<any>()
	@Output() selectItem: EventEmitter<boolean> = new EventEmitter<boolean>()

	public isOpen = false

	constructor() {}

	public onNgSelectChange(event: any): void {
		this.ngModel = event
		this.ngModelChange.emit(this.ngModel)
	}

	public toggleSelect(value: boolean) {
		this.isOpen = value
	}

	writeValue(value: any): void {
		this.ngModel = value
	}

	registerOnChange(fn: any): void {
		this.ngModelChange.subscribe(fn)
	}

	registerOnTouched(fn: any): void {}

	setDisabledState(isDisabled: boolean): void {}

	public onSelect() {
		this.selectItem.emit(true)
	}

}
