<div class="wrapper" [class]="classesNotification">
	<div class="inner">
		<div class="image-container icon">
			<img src='./assets/images/icons/error-notification.svg'
					 alt="error"
					 *ngIf="notificationData?.type === 'error'"
			>
			<!--			warning -->
			<img src='./assets/images/icons/error-notification.svg'
					 alt="error"
					 *ngIf="notificationData?.type === 'warning'"
			>
			<!--			warning -->
			<img src='./assets/images/icons/success-notification.svg'
					 alt="success"
					 *ngIf="notificationData?.type === 'success'"
			>
		</div>

		<div class="content">
			<span class="title">{{notificationData?.title || ''}}</span>
			<p class="description">{{notificationData?.message || ''}}</p>
		</div>

		<ng-container>
			<div class="image-container close"
					 (click)="onHide()"
					 *ngIf="notificationData?.type === 'error' || notificationData?.type === 'warning'"
			>
				<img src='./assets/images/icons/x-light.svg' alt="close">
			</div>

			<div class="image-container close"
					 (click)="onHide()"
					 *ngIf="notificationData?.type === 'success'"
			>
				<img src='./assets/images/icons/x-dark.svg' alt="close">
			</div>
		</ng-container>
	</div>
</div>






















