<div class="wrapper">
	<div class="inner">
		<div class="header">
			<a href="https://investlink.io/">
				<div class="image-container logo">
					<img src="./assets/images/logo-dark.svg" alt="logo">
				</div>
			</a>
			<a href="https://investlink.io/" class="image-container close">
				<img src="./assets/images/icons/x-dark.svg" alt="close">
			</a>
		</div>

		<div class="content">
			<div class="content-inner">
				<img class="image" src="./assets/images/icons/alert.svg" alt="alert">

				<h3 class="title">Вы уже авторизованы на платформе в другой вкладке.</h3>

				<span class="description">Для стабильной работы и защиты ваших торговых данных, пожалуйста, закройте эту вкладку.</span>

				<div class="btns">
					<button type="button" class="btn btn-md btn-solid-gray btn-direct" (click)="close()">Закрыть</button>
					<button type="button" class="btn btn-md btn-primary btn-direct" (click)="tradeHere()">Торговать здесь</button>
				</div>
			</div>
		</div>

		<div class="footer">
			<a [href]="termsOfUser" target="_blank" class="item">Пользовательское соглашение</a>
			<a [href]="privacyPolicy" target="_blank" class="item">Политика конфиденциальности</a>
		</div>
	</div>
</div>
