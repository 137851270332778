<div class="wrapper" [class.disabled]='isDisabled'>
	<div class="trigger" [class.trigger-disabled]='isDisabled' (click)="decrement()">
		<img src='./assets/images/icons/minus.svg' alt="minus">
	</div>

	<input type="number"
				 [placeholder]="placeholder"
				 class="input disable-input-arrow"
				 [(ngModel)]="value"
				 (input)="onInput($event)"
				 [disabled]='isDisabled'
				 (focusout)="onFocusOut()"
	>

	<div class="trigger" (click)="increment()">
		<img src='./assets/images/icons/plus.svg' alt="plus">
	</div>
</div>
