import { Component, ContentChild, Input, TemplateRef } from '@angular/core'
import { PositionFullRowType } from '../core/types/position-full-row.type'

@Component({
	selector: 'app-table-full-row',
	templateUrl: './table-full-row.component.html',
	styleUrls: ['../table.component.scss']
})
export class TableFullRowComponent {

	@Input() position: PositionFullRowType = 'start'

	@ContentChild(TemplateRef) template!: TemplateRef<any>
	constructor() {}

}
