import { NgModule } from '@angular/core'
import { TableComponent } from './table.component'
import { TableColumnComponent } from './table-column/table-column.component'
import { CommonModule, NgTemplateOutlet } from '@angular/common'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { TableFullRowComponent } from './table-full-row/table-full-row.component'

@NgModule({
	declarations: [
		TableComponent,
		TableColumnComponent,
		TableFullRowComponent
	],
	imports: [
		NgTemplateOutlet,
		CommonModule,
		NgxSkeletonLoaderModule
	],
	exports: [
		TableComponent,
		TableColumnComponent,
		TableFullRowComponent
	],
	providers: []
})
export class TableModule { }
