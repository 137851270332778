import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs'
import { ClosePositionInterface } from '../interfaces/close-position.interface'
import {
	PositionsResponseInterface
} from '../interfaces/positions-response.interface'
import { PositionInterface } from '../interfaces/position.interface'
import { WebSocketService } from '../../../services/web-socket.service'
import { UserService } from '../../user-entity/services/user-service'
import { DealInterface } from '../../deal-entity/interfaces/deal.interface'
import { PositionsHelperService } from './positions-helper.service'
import { environment } from '../../../../environments/environment'
import { OptionsHelperService } from '../../options-entity/services/options-helper.service'
import { AssetClassEnum } from '../../../enums/asset-class.enum'
import { OrderInterface } from '../../order-entity/interfaces/order.interface'
import { OrderGeneralHelperService } from '../../order-entity/services/order-general-helper.service'

@Injectable( { providedIn: 'root' } )
export class PositionService {

	public socketPositions$: BehaviorSubject<PositionInterface[]> = new BehaviorSubject<PositionInterface[]>([])
	public isLoadingSocketPositions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

	protected readonly assetClassEnum = AssetClassEnum

	constructor(
		private _http: HttpClient,
		private _webSocketService: WebSocketService,
		private _userService: UserService,
		private _positionHelperService: PositionsHelperService,
		private _optionsHelperService: OptionsHelperService,
		private _orderGeneralHelperService: OrderGeneralHelperService
	) {}

	public getAllPositions(): Observable<PositionsResponseInterface> {
		return this._http.get<PositionsResponseInterface>(`${environment.apiUrl}/alpaca/get_all_positions/`)
	}

	public closePosition(data: ClosePositionInterface): Observable<OrderInterface> {
		return this._http.post<OrderInterface>(`${environment.apiUrl}/orders/close_positions/`, data)
			.pipe(
				map((response: OrderInterface) => {
					return this._orderGeneralHelperService.transformOrders([response])[0]
				})
			)
	}

	public openSocketPositions(): Observable<PositionInterface[]> {
		if (!this._userService.userDetailLocal) return of([])

		this._webSocketService.connect(`${environment.socketUrl}/get_all_positions/`)

		this._webSocketService.sendMessage({
			action: 'get_positions',
			request_id: Date.now(),
			account_id: this._userService.userDetailLocal.broker_id
		})

		return this._webSocketService.onMessage()
			.pipe(
				map((response: PositionInterface[]) => {
					return response.map((position: PositionInterface) => {
						return {
							...position,
							profitability_dollar: this._positionHelperService.getResultProfitability(position, 'dollar'),
							profitability_percent: this._positionHelperService.getResultProfitability(position, 'percent'),
						}
					})
				}),
				tap((response: PositionInterface[]) => {
					this.socketPositions$.next(response)
					this.isLoadingSocketPositions$.next(false)
				})
			)
	}

	public getDeals(symbol: string, type: string): Observable<DealInterface[]> {
		const params: HttpParams = new HttpParams()
			.append('symbol', symbol.toUpperCase())
			.append('activity_type', type.toUpperCase())

		return this._http.get<DealInterface[]>(`${environment.apiUrl}/alpaca/account_activities/`, { params })
			.pipe(
				map((response: DealInterface[]) => {
					return this._positionHelperService.transformDeals(response)
				})
			)
	}

}
