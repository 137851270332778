import { Component } from '@angular/core'

@Component({
	selector: 'app-engineering-works',
	templateUrl: './engineering-works.component.html',
	styleUrls: ['./engineering-works.component.scss']
})
export class EngineeringWorksComponent {

	constructor() { }

}
