import { Injectable } from '@angular/core'
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'

@Injectable({
	providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad {

	constructor(
		private authService: AuthService,
		private router: Router
	) {}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkAuthentication()
	}

	canActivateChild():
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkAuthentication()
	}

	canLoad(): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkAuthentication()
	}

	private checkAuthentication(): boolean {
		const isAuthenticated = this.authService.checkAuth()
		if (isAuthenticated) {
			this.router.navigate(['/cabinet/briefcase'])
			return false
		}

		return true
	}
}
