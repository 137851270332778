import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SelectComponent } from './select.component'
import { FormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { DirectivesModule } from '../../directives/directives.module'

@NgModule({
	declarations: [SelectComponent],
	imports: [CommonModule, FormsModule, NgSelectModule, DirectivesModule],
	exports: [SelectComponent]
})
export class SelectModule {}
