import { Pipe, PipeTransform } from '@angular/core'
import { OrderTypeInterface } from '../interfaces/order-type.interface'
import { OrderTypes } from '../consts/order-types'
import { OrderClassEnum } from '../enums/order-class.enum'

@Pipe({
	name: 'extendedOrderType'
})
export class ExtendedOrderTypePipe implements PipeTransform {

	protected readonly orderClassEnum = OrderClassEnum

	protected readonly orderTypes: OrderTypeInterface[] = OrderTypes

	transform(orderType: string | undefined, orderClass?: string | undefined, orderAttribute?: string | undefined): string {
		if (!orderType) {
			return ''
		}
		if (orderClass === this.orderClassEnum.oco) {
			return orderAttribute || ''
		}
		const foundType: OrderTypeInterface | undefined = this.orderTypes.find((type: OrderTypeInterface) => type.type === orderType)
		return foundType ? foundType.title : orderType
	}

}
