import { Injectable } from '@angular/core'
import { BehaviorSubject, Subscription, timer } from 'rxjs'
import { NotificationInterface } from '../interfaces/notification.interface'
import { NotificationTypeEnum } from '../enums/notification-type.enum'

@Injectable()
export class NotificationService {

	protected readonly notificationTypeEnum = NotificationTypeEnum

	private hideTimeout: Subscription | null = null

	public notificationDataSignal: BehaviorSubject<NotificationInterface | null> = new BehaviorSubject<NotificationInterface | null>(null)
	public notificationOpenSignal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

	constructor() {}

	private _sendNotification(data: NotificationInterface): void {
		if (this.hideTimeout) {
			this.hideTimeout.unsubscribe()
		}

		this.notificationDataSignal.next(data)
		this.notificationOpenSignal.next(true)

		this.hideTimeout = timer(5000)
			.subscribe(() => {
				this.hide()
			})
	}

	public error(title: string, message: string): void {
		this._sendNotification({ type: this.notificationTypeEnum.error, title, message })
	}

	public warning(title: string, message: string): void {
		this._sendNotification({ type: this.notificationTypeEnum.warning, title, message })
	}

	public success(title: string, message: string): void {
		this._sendNotification({ type: this.notificationTypeEnum.success, title, message })
	}

	public hide() {
		this.notificationOpenSignal.next(false)
		if (this.hideTimeout) {
			this.hideTimeout.unsubscribe()
		}
	}

}
