import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'textProfitability'
})
export class TextProfitabilityPipe implements PipeTransform {

	transform(value: number | undefined, type: 'dollar' | 'percent'): string {
		if (type === 'dollar') {
			if (!value) return `$0`
			const formattedValue = Math.abs(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
			if (value > 0) return `+$${formattedValue}`
			if (value < 0) return `-$${formattedValue}`
			return `$0`
		}

		if (type === 'percent') {
			if (!value) return `0%`
			const formattedValue = Math.abs(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
			if (value > 0) return `+${formattedValue}%`
			if (value < 0) return `-${formattedValue}%`
			return `0%`
		}

		return '0'
	}
}
