import { EnvInterface } from './env.interface'

export const environment: EnvInterface = {
	production: false,
	apiUrl: 'https://app-alp-sb1.investlink.io',
	// apiUrl: 'http://192.168.100.35:8080',
	frontUrl: 'https://trade-demo.investlink.io',
	socketUrl: 'wss://ws-alp-sb1.investlink.io/ws'
	// socketUrl: 'ws://192.168.100.35:8080/ws'
}
