import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'
import { AssetInterface } from '../interfaces/asset.interface'
import {
	AssetBySymShortInterface
} from '../interfaces/asset-by-sym-short.interface'

@Injectable()
export class AssetsService {

	/**
	 * Constructor
	 *
	 */
	constructor(private _http: HttpClient) {}
	public getAssetsInfo(symbol: string): Observable<AssetInterface> {
		return this._http.get<AssetInterface>(`${environment.apiUrl}/alpaca/get_exante_asset/?symbol=${symbol.toUpperCase()}`)
	}

	public getAssetBySymShort(symbol: string): Observable<AssetBySymShortInterface> {
		return this._http.get<AssetBySymShortInterface>(`${environment.apiUrl}/alpaca/get_asset_by_sym_short/${symbol.toUpperCase()}/`)
	}

}
