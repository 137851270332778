import { Injectable } from '@angular/core'
import { OptionTypeEnum } from '../enums/option-type.enum'
import { OptionShortTypeEnum } from '../enums/option-short-type.enum'
import { CreateOrderInterface } from '../../order-entity/interfaces/create-order.interface'
import { OptionContractDetailsInterface } from '../interfaces/option-contract-details.interface'
import { OrderSideEnum } from '../../order-entity/enums/order-side.enum'
import { OrderTypeEnum } from '../../order-entity/enums/order-type.enum'
import { PositionInterface } from '../../position-entity/interfaces/position.interface'
import { PositionSideEnum } from '../../position-entity/enums/position-side.enum'

@Injectable({
	providedIn: 'root'
})
export class OptionsHelperService {
	protected readonly optionTypeEnum = OptionTypeEnum
	protected readonly optionShortTypeEnum = OptionShortTypeEnum
	protected readonly orderSideEnum = OrderSideEnum
	protected readonly orderTypeEnum = OrderTypeEnum
	protected readonly positionSideEnum = PositionSideEnum

	constructor() {}

	public getSymbolByOption(symbol: string): string {
		const match: string[] | null = symbol.match(/^[A-Za-z]+/)
		return match ? match[0] : symbol
	}

	public getStrikeBySymbolPosition(symbol: string): number | null {
		const regex: RegExp = /^[A-Z]+\d{6}[PC](\d{8})$/
		const match: RegExpMatchArray | null = symbol.match(regex)
		if (!match) return null

		const strikeRaw: string = match[1]
		const strikeWhole: string = strikeRaw.slice(0, 5)
		const strikeFraction: string = strikeRaw.slice(5, 7)

		return parseFloat(`${parseInt(strikeWhole, 10)}.${strikeFraction}`)
	}

	public getDateBySymbolPosition(symbol: string): string | null {
		const regex: RegExp = /^[A-Z]+(\d{2})(\d{2})(\d{2})[PC]\d{8}$/
		const match: RegExpMatchArray | null = symbol.match(regex)
		if (!match) return null

		const [_, year, month, day] = match
		return `${day}.${month}.${year}`
	}

	public getTypeBySymbolPosition(symbol: string): OptionTypeEnum | null {
		const regex: RegExp = /^[A-Z]+\d{6}([PC])\d{8}$/
		const match: RegExpMatchArray | null = symbol.match(regex)
		if (!match) return null

		const type: string = match[1]
		return type === this.optionShortTypeEnum.put
			? this.optionTypeEnum.put
			: this.optionTypeEnum.call
	}

	public getDescriptionConfirmCreateOrder(
		order: Partial<CreateOrderInterface> | undefined,
		optionContract: OptionContractDetailsInterface | undefined
	): string {
		if (!optionContract || !order) return ''

		const { bid, ask } = optionContract?.results?.last_quote ?? { bid: 0, ask: 0 }
		const symbol: string = order?.symbol ?? ''
		const qty: number = order?.qty ?? 0
		const price: number =
			order?.type === this.orderTypeEnum.market
				? order.side === this.orderSideEnum.buy
					? bid
					: ask
				: +(order?.limit_price ?? 0)
		const strike: number = optionContract?.results?.details?.strike_price ?? 0
		const date: string =
			optionContract?.results.details.expiration_date.split('-').reverse().join('.') ?? ''
		const type: OptionTypeEnum = optionContract?.results.details.contract_type
		const premiumTotal: number = order?.transactionAmount ?? 0

		switch (type) {
			case this.optionTypeEnum.call: {
				if (order.side === this.orderSideEnum.buy) {
					return `Вы покупаете опционы ${type.toUpperCase()} в количестве ${qty} по цене ${price} за шт.
				Базовый актив — ${symbol} стоимостью ${strike} со сроком исполнения ${date}.
				Если ${symbol} не превысит ${strike} к ${date}, опцион истечет, и вы потеряете всю сумму сделки.`
				} else {
					return `Вы продаете опционы ${type.toUpperCase()} в количестве ${qty} по цене ${price} за шт.
				 Базовый актив — ${symbol} стоимостью ${strike} со сроком исполнения ${date}.
				 Если цена ${symbol} превысит ${strike} к ${date}, актив будет продан автоматически по цене ${strike}.
				 Взамен вы получите премию — ${premiumTotal}.`
				}
			}
			case this.optionTypeEnum.put: {
				if (order.side === this.orderSideEnum.buy) {
					return `Вы покупаете опционы ${type} в количестве ${qty} по цене ${price} за шт.
				Базовый актив — ${symbol} стоимостью ${strike} со сроком исполнения ${date}.
				Если ${symbol} не опустится ниже ${strike} к ${date} опцион истечет, и вы потеряете всю сумму сделки.`
				} else {
					return `Вы продаете опционы ${type} в количестве ${qty} по цене ${price} за шт.
				Базовый актив — ${symbol} по цене исполнения ${strike} с датой экспирации ${date}.
				Если цена ${symbol} опустится ниже ${strike} к ${date}, актив будет куплен автоматически по цене ${strike}.
				Взамен вы получите премию — ${premiumTotal}.`
				}
			}
			default:
				return ''
		}
	}

	public getDescriptionConfirmExecutePosition(
		qty: number,
		position: Partial<PositionInterface> | undefined,
		optionContract: OptionContractDetailsInterface | undefined
	): string {
		if (!position || !optionContract) return ''

		const optionType: OptionTypeEnum | null | undefined = position.option_type
		const strike: number = position?.option_strike ?? 0
		const titleBuySell: string = position.side === this.positionSideEnum.long ? 'покупке' : 'продаже'
		const allStocks: number | null = qty * optionContract.results.details.shares_per_contract ?? 0

		return `Вы подтверждаете исполнение ${qty} опционов ${optionType?.toUpperCase()}
			 по цене $${strike} за акцию, это приведет к ${titleBuySell} ${allStocks} акций
			 и закрытию позиции опциона на количество исполняемых контрактов.`
	}
}
