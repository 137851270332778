<div class="wrapper">
	<div class="inner">
		<div class="header">
			<a href="https://investlink.io/">
				<div class="image-container logo">
					<img src="./assets/images/logo-dark.svg" alt="logo">
				</div>
			</a>
			<a href="https://investlink.io/" class="image-container close">
				<img src="./assets/images/icons/x-dark.svg" alt="close">
			</a>
		</div>

		<div class="content">
			<div>
				<div class="download">
					<h2 class="title">Скачайте мобильное приложение Investlink: Trade & Invest для регистрации.</h2>
					<div class="qr image-container">
						<img src="./assets/images/qr-investlink.svg" alt="QR-Investlink">
					</div>
					<span class="description">Просто наведите камеру на QR-код.</span>
					<div class="app">
						<a href="https://apps.apple.com/kz/app/investlink-trade-invest/id1669200360" target="_blank" class="image-container">
							<img src="./assets/images/download-app-store.svg" alt="download-app-store">
						</a>
						<a href="https://play.google.com/store/apps/details?id=broker.investlink.io.investlink_broker" target="_blank" class="image-container">
							<img src="./assets/images/download-google-play.svg" alt="download-google-play">
						</a>
					</div>
				</div>
				<div class="content-footer">
					<span class="content-footer-description">Уже зарегистрированы?</span>
					<a [routerLink]="['/auth/sign-in']" class="content-footer-description link">Войти</a>
				</div>
			</div>
		</div>

		<div class="footer">
			<a [href]="termsOfUser" target="_blank" class="item">Пользовательское соглашение</a>
			<a [href]="privacyPolicy" target="_blank" class="item">Политика конфиденциальности</a>
		</div>
	</div>
</div>
