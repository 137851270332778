import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { privacyPolicy, termsOfUser } from '../../../../core/consts/docs'

@Component({
	selector: 'app-mobile-content',
	templateUrl: './qr-download-app.component.html',
	styleUrls: ['./qr-download-app.component.scss']
})
export class QrDownloadAppComponent {
	protected readonly privacyPolicy = privacyPolicy
	protected readonly termsOfUser = termsOfUser

	constructor(
		private _router: Router
	) {}

	public close() {
		window.location.href = 'https://investlink.io'
	}
}
