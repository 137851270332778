<div class="wrapper">
	<div class="inner">
		<div class="item">
			<div class="image-container">
				<img src="./assets/images/error-page.png" alt="error">
			</div>
		</div>
		<div class="item">
			<div class="image-container logo">
				<img src="./assets/images/logo-dark.svg" alt="error">
			</div>
			<h1 class="headline">Страница не найдена!</h1>
			<div class="text-wrapper">
				<div class="text">
					Возможно, вы ввели неверный адрес или страница была перемещена.
					Пожалуйста, проверьте URL-адрес и попробуйте еще раз.
				</div>
				<div class="text">
					Приносим извинения за неудобства.
				</div>
			</div>
			<div class="btns">
				<button class="btn btn-lg btn-primary btn-direct" (click)="goToBreefcase()">В портфель</button>
			</div>
		</div>
	</div>
</div>
