import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, tap } from 'rxjs'
import { UserDetailInterface } from '../interfaces/user-detail.interface'
import {
	TradingAccountInterface
} from '../interfaces/trading-account.interface'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class UserService {

	constructor(
		private _http: HttpClient
	) { }

	get userDetailLocal(): UserDetailInterface | undefined {
		const userDetailString: string | null = localStorage.getItem('userDetail')
		return userDetailString ? JSON.parse(userDetailString) : undefined
	}

	private _setUserDetailLocal(userDetail: UserDetailInterface): void {
		localStorage.setItem('userDetail', JSON.stringify(userDetail))
	}

	public clearDataUserLocal() {
		localStorage.removeItem('userDetail')
		localStorage.removeItem('questionnaire')
		localStorage.removeItem('prevAssets')
	}

	public getUserDetail(): Observable<UserDetailInterface> {
		return this._http.get<UserDetailInterface>(`${ environment.apiUrl }/user_info/user_details/`)
			.pipe(
				tap(this._setUserDetailLocal.bind(this))
			)
	}

	public updateUserDetail(newData: Partial<UserDetailInterface>) {
		localStorage.setItem('userDetail', JSON.stringify({ ...this.userDetailLocal, ...newData }))
	}

	public getTradingAccount(): Observable<TradingAccountInterface> {
		return this._http.get<TradingAccountInterface>(`${environment.apiUrl}/proxy_api/v1/alpaca/account_trading_data/`)
	}

}
