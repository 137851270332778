export enum OrderStatusEnum {
	new = 'new',
	partially_filled = 'partially_filled',
	filled = 'filled',
	done_for_day = 'done_for_day',
	canceled = 'canceled',
	expired = 'expired',
	replaced = 'replaced',
	pending_cancel = 'pending_cancel',
	pending_replace = 'pending_replace',
	accepted = 'accepted',
	pending_new = 'pending_new',
	rejected = 'rejected',
	calculated = 'calculated',
	accepted_for_bidding = 'accepted_for_bidding',
	stopped = 'stopped',
	suspended = 'suspended',
	held = 'held'
}
