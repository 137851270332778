<div class="wrapper">
	<div class="inner">
		<div class="inner-left">
			<div class="image-container brand">
				<img src="./assets/images/logo-dark.svg" alt="Investlink - Dark">
			</div>
			<h1 class="title">Технические работы</h1>
			<div class="descriptions">
				<p class="descriptions-item">
					Сейчас мы работаем над улучшением нашего сервера для обеспечения  нашего сервера для обеспечения большей скорости и безопасности сервиса.
				</p>
				<p class="descriptions-item">
					Пожалуйста, попробуйте войти в систему позже.
				</p>
				<p class="descriptions-item">
					Приносим извинения за возможные неудобства и благодарим вас за терпение.
				</p>
			</div>
			<p class="author">
				С уважением,
				<span class="author-name">Команда Investlink</span>
			</p>
		</div>

		<div class="inner-right">
			<div class="image-container illustration">
				<img src="./assets/images/illustrations/engineering-works.svg" alt="engineering-works">
			</div>
		</div>
	</div>
</div>
