import { OrderStatusEnum } from '../enums/order-status.enum'

export const OrderStatusRu: { [key in OrderStatusEnum]: string } = {
	[OrderStatusEnum.new]: 'Новый',
	[OrderStatusEnum.partially_filled]: 'Частично',
	[OrderStatusEnum.filled]: 'Исполнен',
	[OrderStatusEnum.done_for_day]: 'Завершен на сегодня',
	[OrderStatusEnum.canceled]: 'Отменен',
	[OrderStatusEnum.expired]: 'Истек',
	[OrderStatusEnum.replaced]: 'Заменен',
	[OrderStatusEnum.pending_cancel]: 'Ожидает отмены',
	[OrderStatusEnum.pending_replace]: 'Ожидает замены',
	[OrderStatusEnum.accepted]: 'Принят',
	[OrderStatusEnum.pending_new]: 'Ожидает создания',
	[OrderStatusEnum.rejected]: 'Отклонен',
	[OrderStatusEnum.calculated]: 'Вычисляется',
	[OrderStatusEnum.accepted_for_bidding]: 'Принят на торги',
	[OrderStatusEnum.stopped]: 'Остановлен',
	[OrderStatusEnum.suspended]: 'Приостановлен',
	[OrderStatusEnum.held]: 'Ожидает'
}
