import { NgModule } from '@angular/core'
import { TextProfitabilityPipe } from './textProfitability.pipe'
import { EllipsisPipe } from './ellipsis.pipe'
import { SearchRestCountryPipe } from './search-rest-country.pipe'
import { PhoneFormatPipe } from './phone-format.pipe'
import { SafeUrlPipe } from './safe-url.pipe'

@NgModule({
	declarations: [
		TextProfitabilityPipe,
		EllipsisPipe,
		SearchRestCountryPipe,
		PhoneFormatPipe,
		SafeUrlPipe
	],
	imports: [],
	exports: [
		TextProfitabilityPipe,
		EllipsisPipe,
		SearchRestCountryPipe,
		PhoneFormatPipe,
		SafeUrlPipe
	]
})
export class CorePipesModule {}
