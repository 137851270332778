import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Subject } from 'rxjs'
import { LoadingService } from '../loading.service'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { takeUntil } from 'rxjs/operators'

@Component({
	selector: 'app-loading-bar',
	templateUrl: './loading-bar.component.html',
	styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnChanges, OnInit, OnDestroy {

	@Input() autoMode = true
	mode: 'determinate' | 'indeterminate'
	progress = 0
	show = false
	private _unsubscribeAll: Subject<any> = new Subject<any>()

	/**
	 * Constructor
	 */
	constructor(private _loadingService: LoadingService, private cd: ChangeDetectorRef) {
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	ngOnChanges(changes: SimpleChanges): void {
		// Auto mode
		if ('autoMode' in changes) {
			// Set the auto mode in the service
			this._loadingService.setAutoMode(coerceBooleanProperty(changes['autoMode'].currentValue))
		}
	}

	ngOnInit(): void {
		// Subscribe to the service
		this._loadingService.mode$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((value) => {
				this.mode = value
			})

		this._loadingService.progress$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((value) => {
				if (typeof value === 'number') {
					this.progress = value
				}
			})

		this._loadingService.show$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((value) => {
				this.show = value
				this.cd.detectChanges()
			})

	}


	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null)
		this._unsubscribeAll.complete()
	}

}
