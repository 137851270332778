import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { privacyPolicy, termsOfUser } from '../../../../core/consts/docs'

@Component({
	selector: 'app-mobile-content',
	templateUrl: './mobile-content.component.html',
	styleUrls: ['./mobile-content.component.scss']
})
export class MobileContentComponent {
	protected readonly privacyPolicy = privacyPolicy
	protected readonly termsOfUser = termsOfUser

	constructor(
		private _router: Router
	) {}

	public close() {
		window.location.href = 'https://investlink.io'
	}
}
