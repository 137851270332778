import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core'

@Directive({
	selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

	@Output() clickOutside: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>()

	constructor(private elementRef: ElementRef) {}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent): void {
		const clickedInside = this.elementRef.nativeElement.contains(event.target)
		if (!clickedInside) {
			this.clickOutside.emit(event)
		}
	}
}
