import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { OrderInterface } from '../../../interfaces/order.interface'
import { OrderService } from '../../../services/order.service'
import { Subject, takeUntil } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'
import {
	NotificationService
} from '../../../../../components/notification/core/services/notification.service'
import { OrderSideEnum } from '../../../enums/order-side.enum'
import { OrderGeneralHelperService } from '../../../services/order-general-helper.service'
import { SlTpParamsInterface } from '../../../../sl-tp-entity/interfaces/sl-tp-params.interface'
import { SlTpHelperService } from '../../../../sl-tp-entity/services/sl-tp-helper.service'

@Component({
	selector: 'app-confirm-cancel-order-sidebar',
	templateUrl: './confirm-cancel-order-sidebar.component.html'
})
export class ConfirmCancelOrderSidebarComponent implements OnDestroy {

	@Input() order: OrderInterface | undefined
	@Output() cancelOrder: EventEmitter<OrderInterface> = new EventEmitter<OrderInterface>()
	@Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>()

	protected readonly orderSideEnum = OrderSideEnum

	private _unSubscribeAll: Subject<any> = new Subject<any>()

	public isLoadingCancel: boolean = false

	get colorOrderSide(): string {
		if (!this.order) return ''
		const startClass = 'sidebar__content__order__header-side'
		if (this.order.side === this.orderSideEnum.sell) {
			return `${startClass}-red`
		}
		return `${startClass}-green`
	}

	get isVisibleStopPrice(): boolean {
		if (!this.order) return false
		return this._orderGeneralHelperService.getVisibleStopPrice(this.order.type)
	}

	get isVisibleLimitPrice(): boolean {
		if (!this.order) return false
		return this._orderGeneralHelperService.getVisibleLimitPrice(this.order.type, this.order.order_class)
	}

	get paramsSlTp(): SlTpParamsInterface {
		return this._slTpHelperService.getParamsSlTp(this.order)
	}

	constructor(
		private _orderService: OrderService,
		private _notificationService: NotificationService,
		private _orderGeneralHelperService: OrderGeneralHelperService,
		private _slTpHelperService: SlTpHelperService
	) {}

	public onCancelOrder(order: OrderInterface | undefined) {
		if (!order) return
		this.isLoadingCancel = true
		this._orderService.cancelOrder(order.id)
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe(
				(response: OrderInterface) => {
					this.isLoadingCancel = false
					this.cancelOrder.emit(response)
					this._notificationService.success('Отмена ордера', 'Ордер успешно отменен')
					this.onClose()
				},
				(error: HttpErrorResponse) => {
					this.isLoadingCancel = false
					this._notificationService.error('Отмена ордера', error?.error?.error?.message || 'Не удалось отменить ордер')
				}
			)
	}

	public onClose(): void {
		this.closeDrawer.emit(true)
	}

	ngOnDestroy() {
		this._unSubscribeAll.next(null)
		this._unSubscribeAll.complete()
	}

}
