import { Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
	standalone: true
})
export class ErrorPageComponent {

	constructor(
		private _router: Router
	) { }

	public goToBreefcase(): void {
		this._router.navigate(['/cabinet/briefcase'])
	}
}
