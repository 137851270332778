import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'stubSymbol'
})
export class StubSymbolPipe implements PipeTransform {

	transform(symbol: string | undefined): string {
		if (!symbol) return ''
		return symbol.length > 2 ? symbol.slice(0, 2) : symbol
	}

}
