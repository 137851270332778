import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ConfirmCancelOrderSidebarComponent } from './confirm-cancel-order-sidebar.component'
import { OrderPipesModule } from '../../../pipes/order-pipes.module'

@NgModule({
	declarations: [
		ConfirmCancelOrderSidebarComponent
	],
	imports: [
		CommonModule,
		OrderPipesModule
	],
	exports: [
		ConfirmCancelOrderSidebarComponent
	],
	providers: []
})
export class ConfirmCancelOrderSidebarModule {}
