import { Injectable } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild, Router,
	RouterStateSnapshot, UrlTree
} from '@angular/router'
import { Observable } from 'rxjs'
import { MinAllowedWidth } from '../consts/min-allowed-width'

@Injectable({
	providedIn: 'root'
})
export class DesktopContentGuard implements CanActivate, CanActivateChild {

	protected readonly minAllowedWidth: number = MinAllowedWidth

	constructor(
		private _router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (window.innerWidth >= this.minAllowedWidth) {
			this._router.navigate(['/'])
		}
		return true
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(childRoute, state)
	}

}
