import { NgModule } from '@angular/core'
import { CounterComponent } from './counter.component'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

@NgModule({
	declarations: [
		CounterComponent
	],
	imports: [
		CommonModule,
		FormsModule
	],
	exports: [
		CounterComponent
	],
	providers: []
})
export class CounterModule { }
