<div class="sidebar sidebar-xl sidebar-closePosition">
	<div class="sidebar__wrapper">
		<div class="sidebar__content">
			<div class="sidebar__header">
				<h3 class="sidebar__header-title">Закрыть позицию</h3>
				<div class="sidebar__header-close" (click)="onClose()">
					<img src="./assets/images/icons/x.svg" alt="x">
				</div>
			</div>
			<div class="sidebar__analytics">
				<div class="sidebar__analytics__column">
					<div class="sidebar__analytics__cell">
						<span class="sidebar__analytics__cell-title">Тикер</span>
						<span class="sidebar__analytics__cell-value">{{position?.symbol}}</span>
					</div>
					<div class="sidebar__analytics__cell">
						<span class="sidebar__analytics__cell-title">Позиция</span>
						<span class="sidebar__analytics__cell-value">{{position?.side | uppercase}}</span>
					</div>
				</div>
				<div class="sidebar__analytics__column">
					<div class="sidebar__analytics__cell">
						<span class="sidebar__analytics__cell-title">Цена</span>
						<span class="sidebar__analytics__cell-value">{{position?.current_price | currency}}</span>
					</div>
					<div class="sidebar__analytics__cell">
						<span class="sidebar__analytics__cell-title">Средняя цена</span>
						<span class="sidebar__analytics__cell-value">{{position?.avg_entry_price | currency}}</span>
					</div>
				</div>
				<div class="sidebar__analytics__column">
					<div class="sidebar__analytics__cell">
						<span class="sidebar__analytics__cell-title">Доходность, $</span>
						<span class="sidebar__analytics__cell-value"
									[class]="getClassByProfitability(tableProfitability.dollar)"
						>{{tableProfitability.dollar | textProfitability: 'dollar'}}</span>
					</div>
					<div class="sidebar__analytics__cell">
						<span class="sidebar__analytics__cell-title">Доходность, %</span>
						<span class="sidebar__analytics__cell-value"
									[class]="getClassByProfitability(tableProfitability.percent)"
						>{{tableProfitability.percent | textProfitability: 'percent'}}</span>
					</div>
				</div>
				<div class="sidebar__analytics__column">
					<div class="sidebar__analytics__cell">
						<span class="sidebar__analytics__cell-title">Всего, шт</span>
						<span class="sidebar__analytics__cell-value">{{totalQty}}</span>
					</div>
					<div class="sidebar__analytics__cell">
						<span class="sidebar__analytics__cell-title">Доступно, шт</span>
						<span class="sidebar__analytics__cell-value">{{qtyAvailable | number}}</span>
					</div>
				</div>
			</div>
			<div class="sidebar__orders" *ngIf='isVisibleOrdersTable'>
				<span class="sidebar__orders-title">Открытые ордера {{ position?.symbol }}</span>
				<div class="sidebar__orders__table">
					<app-table [items]="orders"
										 nestedProp="children_order"
										 noItemsText="У вас нет открытых ордеров"
										 sizeBodyNoItems="small"
					>

						<app-table-column label="Тип ордера">
							<ng-template let-item="item" let-isNested="isNested">
								<div class="table-cell"
										 [ngClass]="{
												 'table-cell-indent-big': isNested && item?.order_class !== orderClassEnum.oco,
												 'table-cell-indent-small': (isNested && item?.order_class === orderClassEnum.oco) || item?.order_class === orderClassEnum.oco
											 }"
								>
										<span class="table-cell-value"
													[ngClass]="{
														'order-side-buy': item.side === orderSideEnum.buy,
														'order-side-sell': item.side === orderSideEnum.sell,
													}"
										>
											{{ item.side_ru }}
										</span>
									<span class="table-cell-sub-value order-cell-type" *ngIf="!isNested">{{item?.type | extendedOrderType: item?.order_class : item?.order_attribute}}</span>
									<span class="table-cell-sub-value order-cell-type" *ngIf="isNested">{{ item | extendedNestedOrderType }}</span>

									<div class="nested-cell-arrow-icon image-container" *ngIf="isNested && item?.order_class !== orderClassEnum.oco">
										<img src="./assets/images/icons/nested-arrow.svg" alt="nested arrow">
									</div>
									<div class="nested-cell-double-arrow-icon image-container" *ngIf="isNested && item?.order_class === orderClassEnum.oco">
										<img src="./assets/images/icons/nested-double-arrow.svg" alt="nested double arrow">
									</div>
								</div>
							</ng-template>
						</app-table-column>

						<app-table-column label="Количество" prop="qty">
							<ng-template let-value="value">
								<span class="table-cell-value">{{ value | number }}</span>
							</ng-template>
						</app-table-column>

						<app-table-column label="Цена">
							<ng-template let-item="item">
								<div class="table-cell">
									<span class="table-cell-value" *ngIf="item?.type === orderTypeEnum.limit">{{item.limit_price?.toFixed(2) || '-'}}</span>
									<span class="table-cell-value" *ngIf="item?.type === orderTypeEnum.stop">{{item.stop_price?.toFixed(2) || '-'}}</span>
									<ng-container *ngIf="item?.type === orderTypeEnum.stop_limit">
										<span class="table-cell-value">{{item.limit_price?.toFixed(2) || '-'}}</span>
										<span class="table-cell-sub-value">{{item.stop_price?.toFixed(2) || '-'}}</span>
									</ng-container>
								</div>
							</ng-template>
						</app-table-column>

						<app-table-column label="Статус / срок">
							<ng-template let-item="item">
								<div class="table-cell">
									<span class="table-cell-value">{{item.status_ru}}</span>
									<span class="table-cell-sub-value order-cell-term">{{item | titleOrderTerm}}</span>
								</div>
							</ng-template>
						</app-table-column>

						<app-table-column label="Действия">
							<ng-template let-item="item" let-isNested="isNested">
								<div class="table-cell-actions" *ngIf="!isNested">
									<div class="table-cell-actions-item" title="Отменить" (click)="openConfirmCancelOrder(item)">
										<img src="./assets/images/icons/close.svg" alt="close">
									</div>
								</div>
							</ng-template>
						</app-table-column>
					</app-table>
				</div>
			</div>
			<div class="sidebar__settings">
				<div class="sidebar__settings__amount">
					<div class="sidebar__settings__amount__range">
						<ngx-slider [(value)]='activeSliderValue'
												[options]="rangeOptions"
												(userChange)='onChangeSlider()'
						></ngx-slider>
					</div>
					<div class="sidebar__settings__amount__inputs">
						<div class="form-control form-control-btn-after form-control-sm" [class.form-control-disabled]='!isActiveRange'>
							<input
								type="number"
								class="input-control disable-input-arrow"
								placeholder="В процентах"
								[(ngModel)]='activePercent'
								(ngModelChange)='onChangePercent()'
								[disabled]='!isActiveRange'
							>
							<span class="btn-after">
									<span class="sidebar__settings__amount__inputs-mark">%</span>
								</span>
						</div>
						<div class="form-control form-control-btn-after form-control-sm" [class.form-control-disabled]='!isActiveRange'>
							<input
								type="number"
								class="input-control disable-input-arrow"
								placeholder="В количестве"
								[(ngModel)]='activeQty'
								(ngModelChange)='onChangeQty()'
								[disabled]='!isActiveRange'
							>
							<span class="btn-after">
									<span class="sidebar__settings__amount__inputs-mark">шт.</span>
								</span>
						</div>
					</div>
				</div>

				<div class='sidebar__settings__order-info'>
					<div class='sidebar__settings__order-info__inner'>
						<div class='sidebar__settings__order-info-item'>
							<app-select
								label="Тип ордера"
								[items]="filteredOrderTypes"
								bindLabel="title"
								bindValue="type"
								[clearable]="false"
								[(ngModel)]="orderType"
								(ngModelChange)='onChangeOrderType()'
							></app-select>
						</div>
						<div class='sidebar__settings__order-info-item'>
							<app-select
								label="Срок ордера"
								[items]="filteredOrderTerms"
								bindLabel="title"
								bindValue="term"
								[clearable]="false"
								[(ngModel)]="orderTerm"
							></app-select>
						</div>
					</div>
					<div class="form-control form-control-btn-after" *ngIf='orderType === orderTypeEnum.limit'>
						<input
							type="number"
							class="input-control disable-input-arrow"
							placeholder="Limit цена"
							[(ngModel)]="limitPrice"
							(focusout)="onFocusOutLimitPrice()"
						>
						<label class="label">Limit цена</label>
						<span class="btn-after">
										<img src="./assets/images/icons/dollar.svg" alt="dollar">
									</span>
					</div>
				</div>
				<div class="sidebar__settings__switch">
					<span>Отменить открытые ордера</span>
					<div class="switch">
						<input type="checkbox" class="switch-input" id="switch-cancel-open-orders-percent"
									 [(ngModel)]="isCancelOpenOrders"
									 (ngModelChange)="onChangeCancelOpenOrders()"
						>
						<label class="switch-label" for="switch-cancel-open-orders-percent"></label>
					</div>
				</div>
			</div>
		</div>
		<div class="sidebar__btns">
			<button type="button" class="btn btn-md btn-solid-gray btn-direct" (click)="onClose()">Отмена</button>
			<button type="button" class="btn btn-md btn-primary btn-direct" [disabled]="isLoadingClosePosition" (click)='confirmClosePosition()'>Закрыть позицию</button>
		</div>
	</div>
</div>
