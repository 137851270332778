import { Routes } from '@angular/router'
import { AuthGuard } from './modules/auth/guards/auth.guard'
import { NoAuthGuard } from './modules/auth/guards/no-auth.guard'
import {
	EngineeringWorksComponent
} from './modules/individual-pages/engineering-works/engineering-works.component'
import { UserDetailResolver } from '../core/entities/user-entity/resolvers/user-detail.resolver'
import { SingleTabGuard } from '../core/guards/single-tab.guard'
import { SingleTabComponent } from './modules/individual-pages/single-tab/single-tab.component'
import {
	MobileContentComponent
} from './modules/individual-pages/mobile-content/mobile-content.component'
import { MobileContentGuard } from '../core/guards/mobile-content.guard'
import { DesktopContentGuard } from '../core/guards/desktop-content.guard'
import {
	QrDownloadAppComponent
} from './modules/individual-pages/qr-download-app/qr-download-app.component'
import { ErrorPageComponent } from './modules/individual-pages/error-page/error-page.component'

export const appRouting: Routes = [
	{
		path: '',
		redirectTo: '/cabinet/briefcase',
		pathMatch: 'full'
	},
	{
		path: 'auth',
		loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
		canActivate: [MobileContentGuard, SingleTabGuard, NoAuthGuard],
		canActivateChild: [MobileContentGuard, SingleTabGuard, NoAuthGuard]
	},
	{
		path: 'verify',
		loadChildren: () => import('./modules/verify/verify.module').then((m) => m.VerifyModule),
		canActivate: [MobileContentGuard, SingleTabGuard, AuthGuard],
		canActivateChild: [MobileContentGuard, SingleTabGuard, AuthGuard]
	},
	{
		path: 'cabinet',
		loadChildren: () => import('./modules/cabinet/cabinet.module').then((m) => m.CabinetModule),
		canActivate: [MobileContentGuard, SingleTabGuard, AuthGuard],
		canActivateChild: [MobileContentGuard, SingleTabGuard, AuthGuard],
		resolve: {
			userDetail: UserDetailResolver
		}
	},
	{
		path: 'engineering-works',
		component: EngineeringWorksComponent,
		pathMatch: 'full',
		canActivate: [MobileContentGuard, SingleTabGuard],
		canActivateChild: [MobileContentGuard, SingleTabGuard]
	},
	{
		path: 'single-tab',
		component: SingleTabComponent,
		pathMatch: 'full',
		canActivate: [MobileContentGuard],
		canActivateChild: [MobileContentGuard]
	},
	{
		path: 'mobile-content',
		component: MobileContentComponent,
		pathMatch: 'full',
		canActivate: [DesktopContentGuard],
		canActivateChild: [DesktopContentGuard]
	},
	{
		path: 'qr-download-app',
		component: QrDownloadAppComponent,
		pathMatch: 'full'
	},
	{
		path: '**',
		component: ErrorPageComponent,
		pathMatch: 'full'
	}
]
