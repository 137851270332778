import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, switchMap, tap } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { UserService } from '../../../../core/entities/user-entity/services/user-service'
import { SignInResponseInterface } from '../interfaces/sign-in-response.interface'
import { SignUpInterface } from '../interfaces/sign-up.interface'
import { UpdatePasswordDataInterface } from '../interfaces/update-password-data.interface'
import { TokensInterface } from '../interfaces/tokens.interface'
import { SignInInterface } from '../interfaces/sign-in.interface'
import { ForgetPasswordInterface } from '../interfaces/forget-password.interface'
import { ValidateTokenAndUid64Interface } from '../interfaces/validate-token-and-uid64.interface'
import { UpdatePasswordResponseInterface } from '../interfaces/update-password-response.interface'
import { SignUpResponseInterface } from '../interfaces/sign-up-response.interface'
import { SignOutInterface } from '../interfaces/sign-out.interface'
import { RefreshTokenResponseInterface } from '../interfaces/refresh-token-response.interface'
import { ForgetPasswordResponseInterface } from '../interfaces/forget-password-response.interface'
import {
	CheckCodeAndChangePasswordInterface
} from '../interfaces/check-code-and-change-password.interface'
import {
	CheckCodeAndChangePasswordResponseInterface
} from '../interfaces/check-code-and-change-password-response.interface'

@Injectable({ providedIn: 'root' })
export class AuthService {

	get refreshToken(): string {
		return localStorage.getItem('refreshToken') || ''
	}

	get accessToken(): string {
		return localStorage.getItem('accessToken') || ''
	}

	constructor(
		private http: HttpClient,
		private _userService: UserService
	) {}

	private signInHandler(response: SignInResponseInterface): Observable<SignInResponseInterface> {
		this.saveTokens(response.tokens)
		return of(response)
	}

	private signUpHandler(response: SignUpResponseInterface): Observable<SignUpResponseInterface> {
		this.saveTokens(response.data.tokens)
		return of(response)
	}

	private refreshTokenHandler(response: RefreshTokenResponseInterface): Observable<RefreshTokenResponseInterface> {
		localStorage.setItem('accessToken', response.access)
		return of(response)
	}

	private saveTokens(tokens: TokensInterface): void {
		localStorage.setItem('accessToken', tokens.access)
		localStorage.setItem('refreshToken', tokens.refresh)
	}

	private removeTokens(): void {
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
	}

	public signIn(candidate: SignInInterface): Observable<SignInResponseInterface> {
		return this.http
			.post<SignInResponseInterface>(`${environment.apiUrl}/auth_db/login/`, candidate)
			.pipe(switchMap(this.signInHandler.bind(this)))
	}

	public signUp(candidate: SignUpInterface): Observable<SignUpResponseInterface> {
		return this.http
			.post<SignUpResponseInterface>(`${environment.apiUrl}/auth_db/registration/`, candidate)
			.pipe(switchMap(this.signUpHandler.bind(this)))
	}

	public updateAccessByRefresh(refresh: string): Observable<RefreshTokenResponseInterface> {
		return this.http
			.post<RefreshTokenResponseInterface>(`${environment.apiUrl}/auth/token/refresh/`, { refresh })
			.pipe(
				tap((response: RefreshTokenResponseInterface) => this.refreshTokenHandler(response))
			)
	}

	public dropData() {
		this.removeTokens()
		this._userService.clearDataUserLocal()
	}

	public signOut(data: SignOutInterface): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/auth_db/logout/`, { refresh: data.refresh })
			.pipe(
				tap(() => {
					this.dropData()
				})
			)
	}

	public forgetPassword(data: ForgetPasswordInterface): Observable<ForgetPasswordResponseInterface> {
		return this.http.post<ForgetPasswordResponseInterface>(`${environment.apiUrl}/auth/request_reset_password_v2/`, data)
	}

	public validateTokenAndUid64(uid64: string, token: string): Observable<ValidateTokenAndUid64Interface> {
		return this.http.get<ValidateTokenAndUid64Interface>(`${environment.apiUrl}/auth/password_reset_confirm/${uid64}/${token}/`)
	}

	public checkCodeAndChangePassword(data: CheckCodeAndChangePasswordInterface): Observable<CheckCodeAndChangePasswordResponseInterface> {
		return this.http.post<CheckCodeAndChangePasswordResponseInterface>(`${environment.apiUrl}/auth/password_reset_complete_v2/`, data)
	}

	public changePassword(data: UpdatePasswordDataInterface): Observable<UpdatePasswordResponseInterface> {
		return this.http.patch<UpdatePasswordResponseInterface>(`${environment.apiUrl}/auth/password_reset_complete/`, data)
	}

	public checkAuth(): boolean {
		return !!(this.accessToken && this.refreshToken)
	}

}
